import React, { useState, useEffect } from "react";
import "./Challenge.scss";
import { isValidEmailAddress, isValidUrl } from "../../../utils/stringChecks";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import SSOButton from "../../../Components/SSOButton";
import SSOInput from "../../../Components/SSOInput";
import Loader from "../../../Components/Loader";
import { urls } from "../../../utils/constant.js";
import { setRootUserCookie } from "../../../utils/cookieMgmt.js";
import QuestionIcon from "../../../question.png";

import axios from "axios";
import { getWhitelabelClient } from "../../../utils/whitelabel";

const gapi = window.gapi;

const Challenge = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [signonOption, setSignonOption] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [googleButtonRendered, setGoogleButtonRendered] = useState(false);
  const [sourceRedirectClient, setSourceRedirectClient] = useState(null);

  useEffect(() => {
    let urlParams = new URLSearchParams(window.location.search);
    let sourceUrl = urlParams.get("continue");

    if (isValidUrl(sourceUrl)) {
      const client = getWhitelabelClient(sourceUrl);
      setSourceRedirectClient(client);
    } else {
      setSourceRedirectClient("intellicar");
    }
  }, []);

  useEffect(() => {
    let urlParams = new URLSearchParams(window.location.search);
    let identifiedEmail = localStorage.getItem("identifiedEmail");
    let userOptions = JSON.parse(localStorage.getItem("userOptions"));

    if (
      identifiedEmail &&
      userOptions &&
      isValidEmailAddress(identifiedEmail)
    ) {
      setEmail(identifiedEmail);
      setSignonOption(userOptions);
    } else {
      window.location.href = "/signin/identifier?" + urlParams.toString();
    }
    // redirectToParticularPage();
  }, []);

  let redirectBack = () => {
    let urlParams = new URLSearchParams(window.location.search);
    window.location.href = "/signin/identifier?" + urlParams.toString();
  };

  let renderResetPasswordLink = () => {
    let urlParams = new URLSearchParams(window.location.search);
    return "/signin/reset-password?" + urlParams.toString();
  };

  let renderSignOnOptions = () => {
    return signonOption.map((option) => {
      if (["password", "local"].includes(option)) {
        return (
          <>
            <SSOInput
              key={option}
              inputType="password"
              placeholder="password"
              callbackFunc={setPassword}
              inputValue={password}
            />
            <Link
              key={"link-text-" + option}
              className="link-text"
              to={renderResetPasswordLink()}
            >
              Forgot Password?
            </Link>
          </>
        );
      } else if (option === "google") {
        setTimeout(() => {
          renderGoogleBtn();
        }, 1000);
        return null;
      } else {
        return option;
      }
    });
  };

  let renderGoogleBtn = () => {
    // console.log("hello google from renderGoogleBtn", googleButtonRendered);

    // if (gapi) {
    //   gapi.signin2.render("google-signin2", {
    //     scope: "profile email",
    //     width: 240,
    //     height: 50,
    //     longtitle: true,
    //     theme: "dark",
    //     onsuccess: onGoogleSignIn,
    //     onfailure: onFailure,
    //   });
    // }

    window.google.accounts.id.initialize({
      client_id:
        "232928276196-blbvekh295b066250ehevqht1ld3lku9.apps.googleusercontent.com",
      callback: handleCredentialResponse,
    });

    const parent = document.getElementById("google_btn");
    window.google.accounts.id.renderButton(parent, {
      size: "large",
      width: 150,
    });

    window.google.accounts.id.prompt();
  };

  let onFailure = (error) => {
    console.log(error);
  };

  const handleCredentialResponse = (res) => {
    apiCallToLoginUser(res.credential);
  };

  let onGoogleSignIn = (googleUser) => {
    setGoogleButtonRendered(true);
    var profile = googleUser.getBasicProfile();
    if (profile.getEmail() !== email) {
      gapi.auth2.getAuthInstance().signOut();
      toast.error(
        "Please use the same email address to login or signin to google account first with the provided email id"
      );
    } else {
      if (!googleButtonRendered) {
        setGoogleButtonRendered(true);
        // console.log(googleUser.getAuthResponse());
        console.log(
          "googleUser.getAuthResponse()",
          googleUser.getAuthResponse()
        );
        apiCallToLoginUser(googleUser.getAuthResponse().id_token);
      }
    }
  };

  let apiCallToLoginUser = (googleToken) => {
    setIsLoading(true);
    let urlParams = new URLSearchParams(window.location.search);
    let sourceUrl = urlParams.get("continue");
    let logintype = JSON.parse(localStorage.getItem("userOptions"))[0];
    let body = {};
    if (logintype === "google") {
      body = getGooglePayload(googleToken);
    } else if (logintype === "password" || logintype === "local") {
      body = getPasswordPayload();
    } else {
      toast.error("Invalid login type");
    }

    let api_url = urls.apiUrl;
    if (sourceUrl && sourceUrl.includes("staging")) {
      api_url = urls.apiUrl;
    }

    console.log("body", body);

    axios
      .post(api_url + "/sso/gettokensinglesignon", body)
      .then(function (response) {
        setIsLoading(false);
        let userdata = response.data.data;
        setRootUserCookie(
          userdata.impPerm,
          userdata.token,
          JSON.stringify(userdata.userinfo)
        );
        // localStorage.setItem("token", userdata.token);
        // localStorage.setItem("userInfo", JSON.stringify(userdata.userinfo));
        // localStorage.setItem("impPerm", userdata.impPerm);

        let signedInEmails =
          JSON.parse(localStorage.getItem("signedInEmails")) || [];
        if (!signedInEmails.includes(localStorage.getItem("identifiedEmail"))) {
          signedInEmails.unshift(localStorage.getItem("identifiedEmail"));
          localStorage.setItem(
            "signedInEmails",
            JSON.stringify(signedInEmails)
          );
        }

        cleanLocalStorage();
        if (userdata.impPerm) {
          window.location.href = "/signin/impersonate?" + urlParams.toString();
        } else {
          if (sourceUrl) {
            window.close();
          } else {
            window.location.href = "/dashboard";
          }
        }
      })
      .catch(function (error) {
        setIsLoading(false);
        toast.error("Please provide proper user details");
        console.log(error);
      });
  };

  let cleanLocalStorage = () => {
    localStorage.removeItem("identifiedEmail");
    localStorage.removeItem("userOptions");
  };

  let getPasswordPayload = () => {
    let username = localStorage.getItem("identifiedEmail");
    let body = {
      user: {
        username: username,
        password: password,
      },
      logintype: "password",
      impersReq: true,
    };

    return body;
  };

  let getGooglePayload = (token) => {
    let body = {
      token: token,
      logintype: "google",
      impersReq: true,
    };

    return body;
  };

  let renderSignInButton = () => {
    if (signonOption.includes("password") || signonOption.includes("local")) {
      return (
        <>
          <div className="btnContainer">
            <SSOButton text="Next" callbackFunc={apiCallToLoginUser} />
          </div>
          <div className="btnLabel">
            <span>press ENTER</span>
          </div>
        </>
      );
    }
  };

  let keyPress = (e) => {
    if (e.keyCode === 13 && email) {
      apiCallToLoginUser();
    }
  };

  return (
    <div
      className="ChallengeScreen"
      onKeyDown={(e) => {
        keyPress(e);
      }}
    >
      <div className="loginBox">
        <Loader isLoading={isLoading} />
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="logo">
          <img
            src={`/images/${sourceRedirectClient}.png`}
            alt={`${sourceRedirectClient} Logo`}
          />
        </div>
        {/* <div
          className="helperButton"
          onClick={() =>
            window.open(
              "https://drive.google.com/file/d/1U3vSv_kbJVG4duavi5G0rdyG7pWHBLkA/view?usp=sharing",
              "_blank"
            )
          }
        >
          <span>Need Help</span>
          <img src={QuestionIcon} alt="help" />
        </div> */}
        <div className="header">Welcome</div>
        <div
          className="emailBox"
          onClick={() => {
            redirectBack();
          }}
        >
          <i className="fas fa-user-circle"></i>
          <span>{email}</span>
          <i className="fas fa-caret-down"></i>
        </div>
        <div className="formControl">
          {renderSignOnOptions()}
          <div className="sso-provider-container">
            <div id="google_btn"></div>
          </div>
        </div>
        {renderSignInButton()}
      </div>
    </div>
  );
};

export default Challenge;
