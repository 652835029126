import React, { useState, useEffect } from "react";
import "./ForgotPassword.scss";
import { isValidEmailAddress, isValidUrl } from "../../../utils/stringChecks";
import OTPInput from "otp-input-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SSOButton from "../../../Components/SSOButton";
import SSOInput from "../../../Components/SSOInput";
import Loader from "../../../Components/Loader";
import { urls } from "../../../utils/constant.js";
import axios from "axios";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { clientProps, getWhitelabelClient } from "../../../utils/whitelabel";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [sentOtp, setSentOtp] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [OTP, setOTP] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [reenterPassword, setReenterPassword] = useState("");
  const [hasOtpError, setHasOtpError] = useState(false);
  const [sourceRedirectClient, setSourceRedirectClient] = useState(null);

  useEffect(() => {
    let urlParams = new URLSearchParams(window.location.search);
    let sourceUrl = urlParams.get("continue");

    if (isValidUrl(sourceUrl)) {
      const client = getWhitelabelClient(sourceUrl);
      setSourceRedirectClient(client);
    } else {
      setSourceRedirectClient("intellicar");
    }
  }, []);

  useEffect(() => {
    let urlParams = new URLSearchParams(window.location.search);
    let identifiedEmail = localStorage.getItem("identifiedEmail");
    let userOptions = JSON.parse(localStorage.getItem("userOptions"));

    if (
      identifiedEmail &&
      userOptions &&
      isValidEmailAddress(identifiedEmail)
    ) {
      setEmail(identifiedEmail);
    } else {
      window.location.href = "/signin/identifier?" + urlParams.toString();
    }
    redirectToParticularPage();
  }, []);

  let setCookiesForImpUser = () => {
    let urlParams = new URLSearchParams(window.location.search);
    let sourceUrl = urlParams.get("continue");
    const client = getWhitelabelClient(sourceUrl);
    window.document.cookie = clientProps[client].getCookieString("delete");

    let obj = {};
    obj.impPerm = localStorage.getItem("impPerm");
    obj.impToken = localStorage.getItem("impToken");
    obj.impUserInfo = localStorage.getItem("impUserInfo");
    obj.signedInEmails = localStorage.getItem("signedInEmails");
    obj.signedInImpUsers = localStorage.getItem("signedInImpUsers");
    obj.skipImp = localStorage.getItem("skipImp");
    obj.token = localStorage.getItem("token");
    obj.userInfo = localStorage.getItem("userInfo");

    window.document.cookie = clientProps[client].getCookieString(
      "add",
      JSON.stringify(obj),
      "authStore"
    );
  };

  let setCookiesForNonImpUser = () => {
    let urlParams = new URLSearchParams(window.location.search);
    let sourceUrl = urlParams.get("continue");
    const client = getWhitelabelClient(sourceUrl);
    window.document.cookie = clientProps[client].getCookieString("delete");
    let obj = {};
    obj.impPerm = localStorage.getItem("impPerm");
    obj.token = localStorage.getItem("token");
    obj.userInfo = localStorage.getItem("userInfo");
    window.document.cookie = clientProps[client].getCookieString(
      "add",
      JSON.stringify(obj),
      "authStore"
    );
  };

  let redirectToParticularPage = async () => {
    let token = localStorage.getItem("token");
    let urlParams = new URLSearchParams(window.location.search);
    let sourceUrl = urlParams.get("continue");
    if (token && token !== undefined) {
      let isValidToken = await verifyToken(token);
      if (isValidToken) {
        let impPerm = localStorage.getItem("impPerm");
        let skipImp = localStorage.getItem("skipImp");
        if (impPerm === "true" && skipImp === "false") {
          let impToken = localStorage.getItem("impToken");
          let isValidImpToken = await verifyToken(impToken);
          if (impToken && impToken !== undefined && isValidImpToken) {
            setCookiesForImpUser();
            if (sourceUrl) {
              window.close();
            } else {
              window.location.href = "/dashboard";
            }
          } else {
            window.location.href =
              "/signin/impersonate?" + urlParams.toString();
          }
        } else {
          setCookiesForNonImpUser();
          if (sourceUrl) {
            window.close();
          } else {
            window.location.href = "/dashboard";
          }
        }
      } else {
        localStorage.removeItem("identifiedEmail");
        localStorage.removeItem("impPerm");
        localStorage.removeItem("impToken");
        localStorage.removeItem("impUserInfo");
        localStorage.removeItem("token");
        localStorage.removeItem("userInfo");
        localStorage.removeItem("userOptions");
        let urlParams = new URLSearchParams(window.location.search);
        let sourceUrl = urlParams.get("continue");
        const client = getWhitelabelClient(sourceUrl);
        window.document.cookie = clientProps[client].getCookieString("delete");
        window.location.href = "/signin/identifier?" + urlParams.toString();
      }
    }
  };

  let verifyToken = async (token) => {
    let urlContinueParams = new URLSearchParams(window.location.search);
    let sourceUrl = urlContinueParams.get("continue");

    let api_url = urls.apiUrl;
    if (sourceUrl && sourceUrl.includes("staging")) {
      api_url = urls.apiUrl;
    }

    return await axios
      .post(api_url + "/sso/verifytoken", { token: token })
      .then((response) => {
        console.log(response.data.data);
        return response.data.data.valid;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  };

  let redirectBack = () => {
    let urlParams = new URLSearchParams(window.location.search);
    window.location.href = "/signin/identifier?" + urlParams.toString();
  };

  let generateOTP = () => {
    setIsLoading(true);
    let body = {
      username: email,
      captcha: recaptchaToken,
      re_version: "v3_web",
    };

    let urlContinueParams = new URLSearchParams(window.location.search);
    let sourceUrl = urlContinueParams.get("continue");

    let api_url = urls.apiUrl;
    if (sourceUrl && sourceUrl.includes("staging")) {
      api_url = urls.apiUrl;
    }

    axios
      .post(api_url + "/api/otp/getemailotp", body)
      .then(function (response) {
        setIsLoading(false);
        console.log(response);
        setSentOtp(true);
        setHasOtpError(false);
      })
      .catch(function (error) {
        setIsLoading(false);
        toast.error(
          "Something went wrong while sending OTP. Please try again."
        );
        console.log(error);
      });
  };

  let resetPassword = () => {
    setIsLoading(true);
    let body = {
      username: email,
      otp: OTP,
      password: newPassword,
    };

    let urlContinueParams = new URLSearchParams(window.location.search);
    let sourceUrl = urlContinueParams.get("continue");

    let api_url = urls.apiUrl;
    if (sourceUrl && sourceUrl.includes("staging")) {
      api_url = urls.apiUrl;
    }

    axios
      .post(api_url + "/api/user/resetpassword", body)
      .then(function (response) {
        let urlParams = new URLSearchParams(window.location.search);
        setIsLoading(false);
        toast.success("Password updated successfully");
        window.location.href = "/signin/identifier?" + urlParams.toString();
      })
      .catch(function (error) {
        setIsLoading(false);
        setHasOtpError(true);
        toast.error(error.response.data.msg);
        setOTP("");
        setNewPassword("");
        setReenterPassword("");
      });
  };

  let renderResendOTPButton = () => {
    if (hasOtpError) {
      return (
        <SSOButton
          text="Resend OTP"
          callbackFunc={generateOTP}
          isDisabled={recaptchaToken ? false : true}
        />
      );
    }
    return null;
  };

  let resetPasswordIsDisabled = () => {
    if (
      OTP &&
      newPassword &&
      reenterPassword &&
      newPassword === reenterPassword &&
      OTP.length === 6
    ) {
      return false;
    }
    return true;
  };

  let isPasswordMatching = () => {
    if (newPassword && reenterPassword && newPassword === reenterPassword) {
      return true;
    }
    return false;
  };

  let renderOTPNextAction = () => {
    if (sentOtp) {
      return (
        <>
          <label>Enter OTP</label>
          <OTPInput
            value={OTP}
            onChange={setOTP}
            autoFocus
            OTPLength={6}
            otpType="number"
            disabled={false}
            className="otp-input-root"
          />
          {/* <SSOInput inputType="text" placeholder="OTP" callbackFunc={setOTP} inputValue={OTP} /> */}
          <br />
          <SSOInput
            inputType="password"
            placeholder="New Password"
            callbackFunc={setNewPassword}
            inputValue={newPassword}
          />
          <br />
          <SSOInput
            inputType="password"
            placeholder="Re-Enter Password"
            callbackFunc={setReenterPassword}
            inputValue={reenterPassword}
            hasError={!isPasswordMatching()}
          />
          <br />
          <SSOButton
            text="Reset Password"
            callbackFunc={resetPassword}
            isDisabled={resetPasswordIsDisabled()}
          />
          <br />
          {renderResendOTPButton()}
        </>
      );
    }
    return (
      <>
        <GoogleReCaptchaProvider reCaptchaKey="6LeI_PIbAAAAAKwv_rbHl8LhYG5Ifyf8R79WM9sP">
          <GoogleReCaptcha
            onVerify={(token) => {
              setRecaptchaToken(token);
            }}
          />
        </GoogleReCaptchaProvider>
        <SSOButton
          text="Get OTP"
          callbackFunc={generateOTP}
          isDisabled={recaptchaToken ? false : true}
        />
      </>
    );
  };

  let keyPress = (e) => {
    if (e.keyCode === 13 && !resetPasswordIsDisabled()) {
      resetPassword();
    }
  };

  return (
    <div
      className="ForgotPasswordScreen"
      onKeyDown={(e) => {
        keyPress(e);
      }}
    >
      <div className="loginBox">
        <Loader isLoading={isLoading} />
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="logo">
          <img
            src={`/images/${sourceRedirectClient}.png`}
            alt={`${sourceRedirectClient} Logo`}
          />
        </div>
        <div className="header">Welcome</div>
        <div
          className="emailBox"
          onClick={() => {
            redirectBack();
          }}
        >
          <i className="fas fa-user-circle"></i>
          <span>{email}</span>
          <i className="fas fa-caret-down"></i>
        </div>
        <div className="formControl">{renderOTPNextAction()}</div>
      </div>
    </div>
  );
};

export default ForgotPassword;
